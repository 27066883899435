
import { Component, Vue } from "vue-property-decorator"
import {
  Consultant,
  AllJobTitlesQuery,
  CountriesQuery,
  RegionsQuery,
  TalentPoolListQuery,
} from "@/gql"
import AppPage from "@/components/layout/AppPage.vue"
import { ConsultantAvailabilities, ConsultantStatuses } from "@/constants"
import { JobFunctions } from "@/constants"
import InviteConsultantForm from "@/components/forms/InviteConsultantForm.vue"

@Component({
  components: {
    AppPage,
    InviteConsultantForm,
  },
})
export default class Pool extends Vue {
  showInviteConsultantDialog = false

  readonly query = TalentPoolListQuery
  readonly search = ["nameContains", "jobTitle", "tag"]
  readonly allJobTitlesQuery = AllJobTitlesQuery
  readonly countriesQuery = CountriesQuery
  readonly regionsQuery = RegionsQuery
  readonly headers = [
    {
      text: "Avatar",
      align: "start",
      value: "avatar",
      sortable: false,
      custom: true,
    },
    {
      text: "Name",
      value: "name",
    },
    {
      text: "Role",
      value: "jobTitle.name",
      sortable: false,
    },
    {
      text: "Practice Area",
      value: "practiceArea",
      sortable: false,
      custom: true,
    },
    {
      text: "Job Function(s)",
      value: "jobFunctions",
      sortable: false,
      custom: true,
    },
    {
      text: "Year joined",
      value: "yearJoined",
      sortable: false,
    },
    {
      text: "Status",
      value: "status",
      sortable: false,
    },
  ]

  get consultantStatuses() {
    const obj: { [key: string]: string } = {}

    for (const key of ConsultantStatuses) {
      obj[key] = key
    }

    return obj
  }

  get availabilityList() {
    return ConsultantAvailabilities.reduce((obj, cur) => {
      obj[cur.value] = cur.text
      return obj
    }, {} as Record<string, string>)
  }

  get jobFunctionListFilters() {
    return JobFunctions.reduce((obj, cur) => {
      obj[cur.value] = cur.text
      return obj
    }, {} as Record<string, string>)
  }

  gotoDetailPage(consultant: Consultant) {
    this.$router.push(this.getDetailRoute(consultant))
  }

  getDetailRoute(record: Consultant) {
    return {
      name: this.$routes.ConsultantDetail,
      params: { id: record.id.toString() },
    }
  }

  jobFunctionList(jobFunctions: string[]) {
    return jobFunctions.map((jobFunction) => {
      return this.sanitizeUnderscores(jobFunction)
    })
  }
}
